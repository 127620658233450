body {
  margin: 0;
  background-color: #f3f3f3;
  font-family: 'Inter', sans-serif;
  font-family: 'Outfit', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toolBarDatagrid {
  margin: 18px
}


.footerBox {
  background-color: #F3F3F3F3;
  border-bottom: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}